import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";
import logo from '../assets/icons/logo.png';
import { link } from "../constants/tailwind-theme";

const WebsiteNavbarComponent = () => (

    <Navbar
        fluid={true}
        style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
        <Navbar.Brand href="/">
            <img
                src={logo}
                className="h-14 sm:h-16"
                alt="divcon Logo"
            />

        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
            
            <Link to="/home" >
                <h2 className={link.heading3}>HOME</h2>
            </Link>

            <Link to="/about">
                <h2 className={link.heading3}>ABOUT US</h2>
            </Link>

            <Link to="/home?navigateTo=work">
                <h2 className={link.heading3}>OUR WORK</h2>
            </Link>

            <Link to="/home?navigateTo=support">
                <h2 className={link.heading3}>SUPPORT US</h2>
            </Link>

            <Link to="/home?navigateTo=team">
                <h2 className={link.heading3}>OUR TEAM</h2>
            </Link>

            <Link to="/home?navigateTo=contact">
                <h2 className={link.heading3}>CONTACT US</h2>
            </Link>
        </Navbar.Collapse>
    </Navbar>
)

export default WebsiteNavbarComponent;