import { twMerge } from "tailwind-merge"

export const text ={
    heading1: "font-heading font-bold text-4xl text-primary-500",
    heading2:"font-heading font-bold text-2xl text-primary-500",
    heading3:"font-heading font-bold text-md text-primary-500",
    heading4:"font-heading font-bold text-sm text-white",
    body1:"font-body font-regular text-sm ",
    body2:"font-body font-regular text-xs ",
}

export const input ={
    default:  `rounded-md w-full h-12 p-4  ${text.body1}`,
}

export const link ={
    heading3: twMerge( "font-heading font-bold text-lg", "text-white" ,"hover:text-primary-500 " ) ,
}

export const button ={
    primary:`rounded-sm  bg-primary-500 active:bg-tertiary-500 justify-center items-center text-center  p-2  `,
    secondary:`rounded-sm  bg-secondary-500 active:bg-tertiary-500 justify-center items-center text-center  p-2`,
    text:`font-body font-bold text-sm text-white text-center uppercase`,
}
