import { Outlet } from "react-router-dom";
import WebsiteNavbarComponent from '../components/WebsiteNavbarComponent';
import WebsiteFooterComponent from '../components/WebsiteFooterComponent';


export const WebsiteLayout = () => {

    return (


            <main className="flex-1 overflow-auto" >
                <div >
                    <Outlet />
                </div>
            </main>


    )
}
export default WebsiteLayout;