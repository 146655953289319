import { AiFillFacebook, AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import { button, text } from "../constants/tailwind-theme";

const TopHeaderComponent = ( {onDonateClick}) => (

    <div className="flex flex-row flex-wrap justify-between items-center bg-secondary-500 p-2">

        <div className="flex flex-row flex-wrap items-center">
            <a className="flex flex-row m-1 mr-2" href="tel:+19087593710" target={"_blank"}>
                <BsFillTelephoneFill color='white' size={15} />
                <h3 className={`${text.body2} font-bold text-white`}>+1 (908) 759-3710</h3>
            </a>
            <a className="flex flex-row m-1 mr-2" href="mailto:foundationthemp@gmail.com" target={"_blank"}>
                <AiOutlineMail color='white' size={15} />
                <h3 className={`${text.body2} font-bold text-white`}>foundationthemp@gmail.com</h3>
            </a>
            <a className="flex flex-row m-1 mr-2"  href="https://facebook.com//THEMP-Foundation-107740550588582/" target={"_blank"}>
                <AiFillFacebook color='white' size={15} />
                <h3 className={`${text.body2} font-bold text-white`}>THEMP Foundation</h3>
            </a>
            <a className="flex flex-row m-1 mr-2" href="https://www.instagram.com/themp_foundation/" target={"_blank"}>
                <AiOutlineInstagram color='white' size={15} />
                <h3 className={`${text.body2} font-bold text-white`}>themp_foundation</h3>
            </a>
        </div>


        <div className="flex">
            <a href='#'  >
                <button onClick={onDonateClick} className={twMerge(button.primary, "mr-4",)}>
                    <span className={`${button.text}`}>DONATE</span>
                </button>
            </a>
            <a target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSc1fyRbQ9ziaJsYf1t942vak0ikYadlyVZt0YVQsZICZ4EJVA/viewform' className={twMerge(`${button.primary}`)} >
                <span className={twMerge(`${button.text} `)}>VOLUNTEER</span>
            </a>
        </div>



    </div>
)

export default TopHeaderComponent;