import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import WebsiteLayout from "./WebsiteLayout";

import AboutPage from "../pages/AboutPage";
import HomePage from "../pages/HomePage";
import TermsAndCondtionsPage from "../pages/TermsAndCondtionsPage";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route >
            {/* Website layout */}
            <Route path="/" element={<WebsiteLayout />} >
                <Route >
                    <Route path="/" element={<HomePage />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/terms" element={<TermsAndCondtionsPage />} />
                    {/* <Route path="/theme" element={<ThemePage />} /> */}
                </Route>
            </Route>





        </Route>
    )
)

export default router;