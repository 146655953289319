import { text as tailwindText } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'
import { FaAngleDoubleRight } from 'react-icons/fa'

const HeadingUnderlinedComponent = ({
    text,
    containerStyle,
    textStyle,
    underlineStyle
}) => {


    return (
        <div className={twMerge("flex flex-col items-center ", containerStyle )}>
           <h1 className={twMerge( tailwindText.heading1,"text-center", textStyle ) }>{text}</h1>
           <hr className={twMerge("border-2 border-primary-500 w-40", underlineStyle)} />

        </div>
    )
}

export default HeadingUnderlinedComponent;