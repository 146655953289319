import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const WebsiteFooterComponent = () => (

    <div className="flex flex-row flex-wrap justify-between bg-primary-500 p-4">
        <a href="https://heyphord.com" target="_blank"><p className={twMerge(`${text.heading3} text-center text-white text-xs w-full`)}>Developed By Heyphord I.T Solutions</p></a>

        <p className={twMerge(`${text.heading3} text-center text-white text-xs`)}>2023© Themp Foundation. All Rights Reserved</p>
    </div>
)

export default WebsiteFooterComponent;