
import TopHeaderComponent from "../components/TopHeaderComponent";
import WebsiteNavbarComponent from "../components/WebsiteNavbarComponent";
import { button, input, text } from "../constants/tailwind-theme";

import { twMerge } from "tailwind-merge";
import HeadingUnderlinedComponent from "../components/HeadingUnderlinedComponent";
import VisionComponent from "../components/VisionComponent";
import WebsiteFooterComponent from "../components/WebsiteFooterComponent";
import ContactUsComponent from "../components/ContactUsComponent";

import background1 from '../assets/images/background1.png';
import background2 from '../assets/images/background2.png';
import background3 from '../assets/images/background3.jpeg';
import education from '../assets/images/education.png';
import health from '../assets/images/health.jpg';

import gallery1 from '../assets/images/gallery/1.jpg';
import gallery2 from '../assets/images/gallery/2.jpg';
import gallery3 from '../assets/images/gallery/3.jpg';
import gallery4 from '../assets/images/gallery/4.jpg';
import gallery5 from '../assets/images/gallery/5.jpg';
import gallery6 from '../assets/images/gallery/6.jpg';

import team1 from '../assets/images/team/1.jpeg';
import team2 from '../assets/images/team/2.jpeg';

import mentorship from '../assets/images/mentorship.png';
import { useFormik } from "formik";
import { getFormErrorMessage, isFormFieldValid } from "../util/formikUtils";

import Carousel from 'better-react-carousel'
import GoalsComponent from "../components/GoalsComponent";
import DonateModal from "../components/DonateModal";
import React from "react";


const TermsAndCondtionsPage = (props) => {

    const [showModal, setShowModal] = React.useState(false);


    return (
        <div id="home" className="flex flex-col">

            {/* Top header */}
            <TopHeaderComponent onDonateClick={() => setShowModal(true)} />

            <div className=" w-full  bg-primary-500">

                <WebsiteNavbarComponent />

                <div className="flex flex-col space-y-4 px-10 py-20 items-center ">

                    <HeadingUnderlinedComponent underlineStyle="border-secondary-500" textStyle="text-white" text="TERMS AND CONDITIONS" />
                    <h2 className={twMerge(text.heading3, "text-white text-center")}>Schorlarship will be awarded based on:</h2>

                    <ul>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>1. Educational background</h2>  </li>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>2. Grades/GPA</h2>  </li>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>3. Field of study/area of interest</h2>  </li>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>4. Family background</h2>  </li>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>5. Level of need</h2>  </li>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>6. Passion of Program</h2>  </li>
                        <li> <h2 className={twMerge(text.heading2, "text-white text-left")}>7. Passion of sponsee</h2>  </li>
                    </ul>


                </div>

            </div>

         
            {/* FOOTER */}
            <ContactUsComponent />

            {/* CREDITS */}
            <WebsiteFooterComponent />

            <DonateModal
                onClose={() => { setShowModal(false) }}
                isVisible={showModal} />

        </div>
    )
}

export default TermsAndCondtionsPage;