import { text } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'
import { FaAngleDoubleRight } from 'react-icons/fa'

const GoalsComponent = ({
    title1,
    containerStyle
}) => {


    return (
        <div className={ twMerge("flex flex-col items-center justify-center border-4 border-primary-500 rounded-md bg-white p-4 h-64  m-2", containerStyle) }>
            <h2 className={twMerge(text.heading2 , "text-center text-secondary-500")}>{title1}</h2>
        </div>
    )
}

export default GoalsComponent;