
import TopHeaderComponent from "../components/TopHeaderComponent";
import WebsiteNavbarComponent from "../components/WebsiteNavbarComponent";
import { button, input, text } from "../constants/tailwind-theme";

import { twMerge } from "tailwind-merge";
import HeadingUnderlinedComponent from "../components/HeadingUnderlinedComponent";
import VisionComponent from "../components/VisionComponent";
import WebsiteFooterComponent from "../components/WebsiteFooterComponent";
import ContactUsComponent from "../components/ContactUsComponent";

import background1 from '../assets/images/background1.png';
import background2 from '../assets/images/background2.png';
import background3 from '../assets/images/background3.jpeg';
import education from '../assets/images/education.png';
import health from '../assets/images/health.jpg';

import gallery1 from '../assets/images/gallery/1.jpg';
import gallery2 from '../assets/images/gallery/2.jpg';
import gallery3 from '../assets/images/gallery/3.jpg';
import gallery4 from '../assets/images/gallery/4.jpg';
import gallery5 from '../assets/images/gallery/5.jpg';
import gallery6 from '../assets/images/gallery/6.jpg';

import team1 from '../assets/images/team/1.jpeg';
import team2 from '../assets/images/team/2.jpeg';

import mentorship from '../assets/images/mentorship.png';
import { useFormik } from "formik";
import { getFormErrorMessage, isFormFieldValid } from "../util/formikUtils";

import Carousel from 'better-react-carousel'
import GoalsComponent from "../components/GoalsComponent";
import DonateModal from "../components/DonateModal";
import React from "react";


const AboutPage = (props) => {

    const [showModal, setShowModal] = React.useState(false);


    return (
        <div id="about" className="flex flex-col">

            {/* Top header */}
            <TopHeaderComponent onDonateClick={()=>setShowModal(true)} />

            <div className=" w-full  bg-primary-500">

                <WebsiteNavbarComponent />

                <div className="flex flex-col space-y-4 px-10 py-20 items-center ">

                    <HeadingUnderlinedComponent underlineStyle="border-secondary-500" textStyle="text-white" text="ABOUT US" />
                    <h2 className={twMerge(text.heading2, "text-white text-center")}>The name of this organization or foundation is The Health, Education, and Mentorship Program (THEMP).</h2>


                </div>

            </div>

            {/* MISSION STATEMENT */}
            <div className="flex flex-col space-y-4 px-10 py-20 items-center bg-tertiary-500">

                <HeadingUnderlinedComponent textStyle="text-secondary-500" text="MISSION STATEMENT" />

                <h2 className={twMerge(text.heading2, "text-black text-center")}>THEMP foundation is a non-profit organization that believes in a world where every child can read. Our mission is to mentor, provide health education and services, and invest in childhood educations to empower the next generation. We do that by creating educational and or vocation training, healthcare services, and mentorship programs and providing necessary resources in underprivileged areas.</h2>

            </div>

            {/* OUR VISION */}
            <div className="flex flex-col space-y-4 px-10 py-20 items-center bg-white">

                <HeadingUnderlinedComponent textStyle="text-secondary-500" text="OUR VISION" />

                <div className="flex flex-row flex-wrap w-full ">

                    <VisionComponent
                        image={mentorship}
                        title1={"MENTORSHIP"}
                        title2="We mentor kids to sharpen their skills by pairing them to professionals in the field (e.g. music, soccer, etc.)"
                        containerStyle={"md:w-4/12"}
                    />
                    <VisionComponent
                        image={health}
                        title1={"HEALTH"}
                        title2="We provide health services to the underprivileged communities in the US and Ghana. In addition, to educate them on healthy lifestyle."
                        containerStyle="md:w-4/12"

                    />
                    <VisionComponent
                        image={education}
                        title1={"EDUCATION"}
                        title2="We provide education and or vocational trainings to children who are less fortunate and assist them in furthering their education."
                        containerStyle={"md:w-4/12"}

                    />
                </div>

                
            </div>


            {/* GOALS AND OBJECTIVES */}
            <div className="flex flex-col space-y-4 px-10 py-20 items-center bg-primary-300">

                <HeadingUnderlinedComponent textStyle="text-secondary-500" text="GOALS AND OBJECTIVES" />

                <div className="flex flex-row flex-wrap w-full justify-center ">

                    <GoalsComponent
                        title1={"To support children and young adults to further their education"}
                        containerStyle={"w-full md:w-3/12"}
                    />
                    <GoalsComponent
                        title1={"To make sure every child and young adult have the opportunity to fulfill their dreams"}
                        containerStyle="w-full md:w-3/12"

                    />
                    <GoalsComponent
                        title1={"To instill Godly character in the children"}
                        containerStyle={"w-full md:w-3/12"}

                    />
                    <GoalsComponent
                        title1={"To build a foundation house for the homeless to find solace"}
                        containerStyle={"w-full md:w-3/12"}

                    />
                    <GoalsComponent
                        title1={"To feed and provide for 20 children "}
                        containerStyle={"w-full md:w-3/12"}

                    />
                </div>

                
            </div>

            {/* FOOTER */}
            <ContactUsComponent />

            {/* CREDITS */}
            <WebsiteFooterComponent />

            <DonateModal
                onClose={() => { setShowModal(false) }}
                isVisible={showModal} />

        </div>
    )
}

export default AboutPage;