
import TopHeaderComponent from "../components/TopHeaderComponent";
import WebsiteNavbarComponent from "../components/WebsiteNavbarComponent";
import { button, input, text } from "../constants/tailwind-theme";

import { twMerge } from "tailwind-merge";
import HeadingUnderlinedComponent from "../components/HeadingUnderlinedComponent";
import VisionComponent from "../components/VisionComponent";
import WebsiteFooterComponent from "../components/WebsiteFooterComponent";
import ContactUsComponent from "../components/ContactUsComponent";

import background1 from '../assets/images/background1.jpg';
import background2 from '../assets/images/background2.png';
import background3 from '../assets/images/background3.jpeg';
import education from '../assets/images/education.png';
import health from '../assets/images/health.jpg';

import gallery1 from '../assets/images/gallery/1.jpg';
import gallery2 from '../assets/images/gallery/2.jpg';
import gallery3 from '../assets/images/gallery/3.jpg';
import gallery4 from '../assets/images/gallery/4.jpg';
import gallery5 from '../assets/images/gallery/5.jpg';
import gallery6 from '../assets/images/gallery/6.jpg';

import team1 from '../assets/images/team/1.jpeg';
import team2 from '../assets/images/team/2.jpeg';

import mentorship from '../assets/images/mentorship.png';
import { useFormik } from "formik";
import { getFormErrorMessage, isFormFieldValid } from "../util/formikUtils";

import Carousel from 'better-react-carousel'
import React from "react";
import DonateModal from "../components/DonateModal";
import { useSearchParams } from "react-router-dom";


const HomePage = (props) => {

    const [queryParams] = useSearchParams();
    const idToScrollTo = queryParams.get("navigateTo");

    const [showModal, setShowModal] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            subject: "",
            message: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.name) {
                errors.name = "Name is required";
            }
            if (!values.subject) {
                errors.subject = "Subject is required";
            }
            if (!values.message) {
                errors.message = "Message is required";
            }

            return errors;
        },

        onSubmit: (values) => {

            console.log("on submit", 234);

            let link = `mailto:foundationthemp@gmail.com?subject=${values.subject}&body=${values.message}%0D%0A%0D%0ASent by: ${values.name}`
            window.open(
                link,
                '_blank' // <- This is what makes it open in a new window.
            );


        },
    });

    React.useEffect(()=>{
        console.log({idToScrollTo});
        let scrollto = document.getElementById(idToScrollTo);
        if (scrollto){
            scrollto.scrollIntoView({behavior:"smooth", })
        }
    },[idToScrollTo]);

    return (
        <div id="home" className="flex flex-col">

            {/* Top header */}
            <TopHeaderComponent onDonateClick={()=>setShowModal(true)} />

            <div className=" w-full  bg-center bg-cover" style={{ backgroundImage: `url(${background1})` }}>

                <WebsiteNavbarComponent />

                <div className="flex flex-col items-center justify-center  h-96 lg:h-[600px] px-4">

                    <h1 className={twMerge(text.heading1, "text-2xl md:text-4xl text-white text-center")} >CLICK THE BUTTON BELOW TO APPLY FOR THE THEMP SCHOLAR AID </h1>
                    <a href="terms" target={"_blank"}>
                        <h1 className={twMerge(text.heading4, " text-white text-center underline my-8")} >Click here to read the Terms and Conditions before applying  </h1>
                    </a>
                    <a href='https://www.jotform.com/form/223554730892158' target="_blank" className="animate-bounce" >
                        <div className={twMerge(button.primary, "px-10 py-3 mt-4")}>
                            <span className={twMerge(button.text)}>APPLY NOW</span>
                        </div>
                    </a>
                </div>
            </div>

            {/* OUR VISION */}
            <div className="flex flex-col space-y-4 px-10 py-20 items-center bg-white">

                <HeadingUnderlinedComponent textStyle="text-secondary-500" text="OUR VISION" />

                <div className="flex flex-row flex-wrap w-full ">

                    <VisionComponent
                        image={mentorship}
                        title1={"MENTORSHIP"}
                        title2="We mentor kids to sharpen their skills by pairing them to professionals in the field (e.g. music, soccer, etc.)"
                        containerStyle={"md:w-4/12"}
                    />
                    <VisionComponent
                        image={health}
                        title1={"HEALTH"}
                        title2="We provide health services to the underprivileged communities in the US and Ghana. In addition, to educate them on healthy lifestyle."
                        containerStyle="md:w-4/12"

                    />
                    <VisionComponent
                        image={education}
                        title1={"EDUCATION"}
                        title2="We provide education and or vocational trainings to children who are less fortunate and assist them in furthering their education."
                        containerStyle={"md:w-4/12"}

                    />
                </div>

                <a href="about"  >
                    <div className={twMerge(button.primary, " w-80 mt-4")}>
                        <span className={twMerge(button.text)}>SEE MORE</span>
                    </div>
                </a>
            </div>

            {/* VOLUNTEER /DONATE */}
            <div id="support" className="flex flex-row flex-wrap justify-between px-10 py-20 bg-cover bg-center" style={{ backgroundImage: `url(${background2})` }}>

                {/* volunteer */}
                <div className="border-0 w-full md:w-6/12">
                    <h1 className={twMerge(text.heading1, " text-white")} >We can't achieve our goals without your help </h1>

                    <button onClick={()=>setShowModal(true)} className={twMerge(button.primary, "w-80 px-10 py-3 mt-4")}>
                        <span className={twMerge(button.text)}>DONATE NOW</span>
                    </button>
                </div>

                {/* donate */}
                <div className="flex flex-col items-end border-0 w-full md:w-6/12">
                    <h1 className={twMerge(text.heading1, " text-white text-right mt-40")} >Become a volunteer, lets inspire these kids</h1>
                    <a href='https://www.jotform.com/form/223554730892158' target="_blank"  >
                        <div className={twMerge(button.secondary, "w-80 px-10 py-3 mt-4")}>
                            <span className={twMerge(button.text)}>APPLY NOW </span>
                        </div>
                    </a>
                </div>
            </div>

            {/* GALLERY */}
            <div id="work" className="flex flex-col space-y-4 px-10 py-20 items-center bg-tertiary-500">
                <HeadingUnderlinedComponent underlineStyle={"mb-8"} textStyle="text-secondary-500" text="WHAT WE'VE BEEN UP TO" />

                <Carousel
                    containerClassName="w-full border"
                    autoplay={3000}
                    showDots
                    loop
                    gap={1}
                    cols={3}
                    responsiveLayout={[
                        {
                            breakpoint: 800,
                            cols: 1,
                            rows: 1,
                            gap: 10,
                            loop: true,
                            autoplay: 2000,
                            showDots: true
                        }
                    ]}
                >
                    <Carousel.Item>
                        <img src={gallery1} className="w-full" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={gallery2} className="w-full" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={gallery3} className="w-full" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={gallery4} className="w-full" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={gallery5} className="w-full" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={gallery6} className="w-full" />
                    </Carousel.Item>
                </Carousel>

            </div>

            {/* OUR TEAM */}
            <div id="team" className="flex flex-col space-y-4 px-10 py-20 items-center bg-white">
                <HeadingUnderlinedComponent underlineStyle={"mb-8"} textStyle="text-secondary-500" text="OUR TEAM" />

                <div className="flex flex-row justify-center flex-wrap space-x-4">

                    <div className="">
                        <img src={team1} className="w-full h-96" />
                        <h2 className={twMerge(text.heading2, "text-center text-secondary-500")}>Emma Afrane</h2>
                        <h2 className={twMerge(text.heading3, "text-center text-secondary-500")}>President & Founder</h2>
                    </div>
                    <div className="">
                        <img src={team2} className="w-full h-96" />
                        <h2 className={twMerge(text.heading2, "text-center text-secondary-500")}>Winifred Afrane</h2>
                        <h2 className={twMerge(text.heading3, "text-center text-secondary-500")}>Vice President & Financial Auditor</h2>
                    </div>

                </div>
            </div>

            {/* CONTACT US */}

            <form id="contact" action="#" className="flex flex-col space-y-4 items-center justify-center py-20 px-10 bg-cover bg-center bg-fixed" style={{ backgroundImage: `url(${background3})` }}>
                <HeadingUnderlinedComponent textStyle="text-white" text="GET IN TOUCH" />

                <h2 className={twMerge(text.heading2, "text-center text-white")}>DO YOU HAVE ANY COMMENTS, SUGGESTIONS OR PERHAPS A PLACE YOU WANT US TO VISIT </h2>


                <input required className={`${input.default}`} id="name" name="name" onChange={formik.handleChange} value={formik.values.name} type={"text"} placeholder="NAME" />
                {getFormErrorMessage(formik, "name")}
                <input required className={`${input.default}`} id="subject" name="subject" onChange={formik.handleChange} value={formik.values.subject} type={"text"} placeholder="SUBJECT" />
                {getFormErrorMessage(formik, "subject")}

                <textarea required className={`${input.default} h-24`} id="message" name="message" onChange={formik.handleChange} value={formik.values.message} type={""} placeholder="MESSAGE" />
                {getFormErrorMessage(formik, "message")}


                <button type="submit" onClick={formik.handleSubmit} className={twMerge(button.secondary, "px-10 py-3")}>
                    <span className={twMerge(button.text)}>DONATE NOW </span>
                </button>
            </form>


            {/* FOOTER */}
            <ContactUsComponent />

            {/* CREDITS */}
            <WebsiteFooterComponent />

            <DonateModal
                onClose={() => { setShowModal(false) }}
                isVisible={showModal} />


        </div>
    )
}

export default HomePage;