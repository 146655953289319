export const isFormFieldValid = (formik, name) =>
  !!(formik.touched[name] && formik.errors[name]);

export const getFormErrorMessage = (formik ,name) => {
  return (
    isFormFieldValid(formik, name) && (
      <small className="p-2 bg-red-600 border-round-sm text-white">
        {formik.errors[name]}
      </small>
    )
  );
};