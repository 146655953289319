import { text } from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'
import { FaAngleDoubleRight } from 'react-icons/fa'

const VisionComponent = ({
    title1,
    title2,
    image,
    containerStyle
}) => {


    return (
        <div className={ twMerge("flex flex-col items-center p-4", containerStyle) }>
            <img className="rounded-full h-48 w-48" src={image} alt="" />
            <h2 className={twMerge(text.heading2 , "text-secondary-500")}>{title1}</h2>
            <h2 className={twMerge(text.heading3 ,"text-black","text-center")}>{title2}</h2>
        </div>
    )
}

export default VisionComponent;