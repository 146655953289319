import { AiFillFacebook, AiOutlineInstagram, AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs"; import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const ContactUsComponent = () => (

    <div className="flex flex-row flex-wrap justify-between px-10 py-20 bg-secondary-500">

        <div className="w-full md:w-4/12 border-0 p-4 ">
            <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>Contact Us</h1>

            <div className="flex flex-row flex-wrap items-center">
                <a className="flex flex-row m-1 mr-2" href="tel:+19087593710" target={"_blank"}>
                    <BsFillTelephoneFill color='white' size={18} />
                    <h3 className={twMerge(text.heading3, "text-white")}>+1 (908) 759-3710</h3>
                </a>
                <a className="flex flex-row m-1 mr-2" href="mailto:foundationthemp@gmail.com" target={"_blank"}>
                    <AiOutlineMail color='white' size={18} />
                    <h3 className={twMerge(text.heading3, "text-white")}>foundationthemp@gmail.com</h3>
                </a>
                <a className="flex flex-row m-1 mr-2" href="https://facebook.com//THEMP-Foundation-107740550588582/" target={"_blank"}>
                    <AiFillFacebook color='white' size={18} />
                    <h3 className={twMerge(text.heading3, "text-white")}>THEMP Foundation</h3>
                </a>
                <a className="flex flex-row m-1 mr-2" href="https://www.instagram.com/themp_foundation/" target={"_blank"}>
                    <AiOutlineInstagram color='white' size={18} />
                    <h3 className={twMerge(text.heading3, "text-white")}>themp_foundation</h3>
                </a>
            </div>
        </div>

        <div className="w-full md:w-4/12 border-0 p-4 ">
            <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>ABOUT US</h1>
            <h1 className={twMerge(`${text.heading3} text-justify text-white mb-4`)}>THEMP foundation is a non-profit organization that believes in a world where every child can read. Our mission is to mentor, provide health education and services, and invest in childhood educations to empower the next generation. We do that by creating educational and or vocation training, healthcare services, and mentorship programs and providing necessary resources in underprivileged areas.</h1>

        </div>

        <div className="w-full md:w-4/12 border-0 p-4">
            <h1 className={twMerge(`${text.heading2} text-start text-white mb-4`)}>Links</h1>
            <Link to="/home?navigateTo=home"> <h2 className={twMerge(`${text.heading3} text-start text-white hover:text-primary-500`)}>&#x2022; HOME</h2></Link>
            <Link to="/about?navigateTo=about"> <h2 className={twMerge(`${text.heading3} text-start text-white hover:text-primary-500`)}>&#x2022; ABOUT US</h2></Link>
            <Link to="/home?navigateTo=work"> <h2 className={twMerge(`${text.heading3} text-start text-white hover:text-primary-500`)}>&#x2022; OUR WORK</h2></Link>
            <Link to="/home?navigateTo=support"> <h2 className={twMerge(`${text.heading3} text-start text-white hover:text-primary-500`)}>&#x2022; SUPPORT US</h2></Link>
            <Link to="/home?navigateTo=team"> <h2 className={twMerge(`${text.heading3} text-start text-white hover:text-primary-500`)}>&#x2022; OUR TEAM</h2></Link>
            <Link to="/home?navigateTo=contact"> <h2 className={twMerge(`${text.heading3} text-start text-white hover:text-primary-500`)}>&#x2022; CONTACT US</h2></Link>
        </div>


    </div>
)

export default ContactUsComponent;