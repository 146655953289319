import { text ,button} from "../constants/tailwind-theme";
import { twMerge } from 'tailwind-merge'
import { Modal } from "flowbite-react";

const DonateModal = ({
    isVisible,
    onClose
}) => {


    return (
        <Modal
                position="top-center"
                show={isVisible}
                size="md"
                popup={true}
                onClose={onClose}
            >
                <Modal.Header className="ml-4">
                    <h1 className={twMerge(text.heading2, "text-secondary-500")}>Donate</h1>
                </Modal.Header>
                <Modal.Body className="space-y-4">
                    <div>

                        <h1 className={twMerge(text.heading2, "text-secondary-500")}>1. Donate via Mobile Money</h1>
                        <h3 className={twMerge(text.heading3, "text-secondary-500")}>MTN: 054 864 1141</h3>
                    </div>
                    <div>

                        <h1 className={twMerge(text.heading2, "text-secondary-500")}>2. Donate via DonnerBox</h1>


                        <a href="https://donorbox.org/feeding-the-orphans" target="_parent"  >
                            <div className={twMerge(button.primary)}>
                                <span className={twMerge(button.text)}>CLICK HERE TO MAKE DONATION</span>
                            </div>
                        </a>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose} className={twMerge(button.primary)}>
                                <span className={twMerge(button.text)}>CLOSE</span>
                            </button>

                </Modal.Footer>
            </Modal>
    )
}

export default DonateModal;